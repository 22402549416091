import * as React from 'react'
import * as hoverCard from '@zag-js/hover-card'
import clsx from 'clsx'
import ProfileDropdown from './profile-dropdown'
import classes from './styles.module.css'
import {useMachine, normalizeProps, Portal} from '@zag-js/react'
import {motion} from 'framer-motion'
import {useNavigate} from 'react-router'
import {GET_PROFILE} from 'app/routing/getters'
import {useSidebarMinimized} from 'app/store/layout'
import {useCurrentUser, useLogout} from 'app/store/auth'
import {getUsername} from 'app/utils/helper/user'
import {USER_STATUS} from 'types/user'
import {ICONS} from 'app/utils/constants/icon'
import {Avatar, SVG} from '@hybr1d-tech/charizard'
import {PROFILE_MENU_ITEMS} from './profile-dropdown/ProfileDropdown'
import {useDisclosure} from 'hybrid-ui/hooks/use-disclosure'
import {ZENEX_APP_ORIGIN} from 'app/configs'
import {StorageService} from 'app/services'

export default function ProfileBox({isMobile = false}) {
  const [state, send] = useMachine(
    hoverCard.machine({
      id: React.useId(),
      positioning: {placement: 'right-start'},
      openDelay: 300,
      closeDelay: 200,
    }),
  )

  const api = hoverCard.connect(state, send, normalizeProps)
  const currentUser = useCurrentUser()
  const isMini = useSidebarMinimized()
  const isIncognito = currentUser?.status === USER_STATUS.INCOGNITO
  const navigate = useNavigate()
  const condition = !isMini || isMobile
  const logout = useLogout()
  const {isOpen, onClose, onOpen} = useDisclosure()

  //! temporary, move this logic to use app store modules
  const showZenEx = ZENEX_COMPANY_IDS.includes(currentUser?.company.id)

  const handleProfileClick = () => {
    if (!isIncognito) {
      navigate(GET_PROFILE(currentUser?.id))
    }
  }

  const handleMenuItemClick = (itemId, e) => {
    e.preventDefault()
    e.stopPropagation()
    switch (itemId) {
      case PROFILE_MENU_ITEMS.PROFILE:
        handleProfileClick()
        break
      case PROFILE_MENU_ITEMS.NOTIFICATION:
        api.setOpen(false)
        setTimeout(() => {
          onOpen()
        }, 0)
        break
      case PROFILE_MENU_ITEMS.ZENEX_SWITCHER:
        handleZenexSwitch()
        break
      case PROFILE_MENU_ITEMS.SIGN_OUT:
        logout()
        break
    }
  }

  return isMini && !isMobile ? (
    <>
      <div {...api.getTriggerProps()} style={{width: '100%'}}>
        <Profile
          {...{
            isMini,
            isIncognito,
            handleProfileClick,
            currentUser,
            condition,
            isMobile,
            isOpen,
            onOpen,
            onClose,
          }}
        />
      </div>
      {api.open && (
        <Portal>
          <div {...api.getPositionerProps()}>
            <div {...api.getContentProps()} className={classes.content}>
              <div className={classes.profileBoxHoverCard}>
                <Avatar
                  user={currentUser}
                  imageStyles={{width: '32px', height: '32px', borderRadius: '8px'}}
                />
                <div>
                  <p className={'zap-caption-semibold'}>{getUsername(currentUser)}</p>
                  <p
                    className={'zap-content-regular'}
                    style={{
                      color: 'var(--text-secondary)',
                      maxWidth: '140px',
                      textOverflow: 'ellipsis',
                      overflowX: 'hidden',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {currentUser?.work_email}
                  </p>
                </div>
              </div>
              {profileMenuItems?.map(item => (
                <div
                  key={item.id}
                  className={classes.menu}
                  onClick={e => handleMenuItemClick(item.id, e)}
                >
                  <SVG path={item.icon} svgClassName={classes.logo} />
                  <span className={clsx(classes.menuLabel, 'zap-subcontent-medium')}>
                    {item.label}
                  </span>
                </div>
              ))}

              {showZenEx && (
                <div
                  key={PROFILE_MENU_ITEMS.ZENEX_SWITCHER}
                  className={classes.menu}
                  onClick={e => handleMenuItemClick(PROFILE_MENU_ITEMS.ZENEX_SWITCHER, e)}
                >
                  <SVG
                    path={ICONS.zenexSwitcher}
                    width={20}
                    height={20}
                    customSpanStyles={{marginRight: '-4px'}}
                  />
                  <span
                    className={clsx(classes.menuLabel, 'zap-subcontent-medium')}
                    style={{marginLeft: '-2px'}}
                  >
                    Go to ZenEx
                  </span>
                  <SVG
                    path={ICONS.creditCardExternal}
                    width={16}
                    height={16}
                    customSpanStyles={{marginLeft: 'auto'}}
                  />
                </div>
              )}
              <div
                key={PROFILE_MENU_ITEMS.SIGN_OUT}
                className={classes.menu}
                onClick={e => handleMenuItemClick(PROFILE_MENU_ITEMS.SIGN_OUT, e)}
              >
                <SVG path={ICONS.logout} svgClassName={classes.logoRed} />
                <span className={clsx(classes.menuLabelRed, 'zap-subcontent-medium')}>
                  Sign out
                </span>
              </div>
            </div>
          </div>
        </Portal>
      )}
    </>
  ) : (
    <Profile
      {...{
        isMini,
        isIncognito,
        handleProfileClick,
        currentUser,
        condition,
        isMobile,
        isOpen,
        onOpen,
        onClose,
      }}
    />
  )
}

function Profile({
  isMini,
  isIncognito,
  handleProfileClick,
  currentUser,
  condition,
  isMobile,
  isOpen,
  onOpen,
  onClose,
}) {
  return (
    <div
      className={clsx(classes.profileBox, isMini && !isMobile && classes.boxMini, {
        [classes.incognito]: isIncognito,
      })}
      onClick={handleProfileClick}
    >
      <div className={classes.profileBoxLeft}>
        <Avatar
          user={currentUser}
          imageStyles={{width: '32px', height: '32px', borderRadius: '8px'}}
        />
        {condition && (
          <motion.div initial={{opacity: 0}} animate={{opacity: 1}} transition={{duration: 0.6}}>
            <div className={classes.name}>{getUsername(currentUser)}</div>
            <div className={classes.title}>{currentUser?.work_email}</div>
          </motion.div>
        )}
      </div>

      <ProfileDropdown {...{isOpen, onClose, onOpen}} />
    </div>
  )
}

export const profileMenuItems = [
  {
    id: PROFILE_MENU_ITEMS.PROFILE,
    label: 'Profile',
    icon: ICONS.settingsCog,
  },
  {
    id: PROFILE_MENU_ITEMS.NOTIFICATION,
    label: 'Notifications',
    icon: ICONS.bellBlack,
  },
]

export const handleZenexSwitch = () => {
  const currentAuthKey = StorageService.getAuthKey()
  const currentBrowserId = StorageService.getBrowserId()

  if (currentAuthKey && currentBrowserId) {
    const params = new URLSearchParams({
      token: currentAuthKey,
      browser_id: currentBrowserId,
      redirect_to: '/',
    })

    window.location.href = `${ZENEX_APP_ORIGIN}/zenex?${params.toString()}`
  } else {
    window.location.href = ZENEX_APP_ORIGIN
  }
}

const ZENEX_COMPANY_IDS = [
  // staging dev@hybr1d.io
  '99fe5378-d0da-4735-9167-0ba35578e168',
  'e0a98e70-b2e1-40f3-bea4-b462e5450f3c',
  '274e1d6f-3ce0-4da1-9769-abd387e29b4a',
  // staging dev+00990@hybr1d.io
  'f1dae23f-912c-4888-8a58-a9fb138c5fd1',
]
